<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'diagnosis'}"></work-subject>
        <hr>
        <h3>Hasta tanı seçme & yeni tanı ekleme komponenti</h3>
        <diagnosis-selection :p_diagnosisData="d_diagnosisData" :p_patientData="d_patientData"></diagnosis-selection>
        <hr>
        <template v-for="(code_example, code_example_index)  in d_componentExample.schema_drugs">
          <h3> {{ code_example.name }} </h3>
          <div class="code-text">{{ code_example.code }}</div>
        </template>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'TreatmentSchemaSystemDrugs'}"></sql-database>
        <hr>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import {
  default as WorkSubject } from '@/components/widgets/WorkSubject';
import {
  default as DiagnosisSelection
} from '@/components/widgets/DiagnosisSelection';
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import { mapGetters } from 'vuex';
export default {
  name: 'Diagnosis',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    WorkSubject,
    DiagnosisSelection,
    SqlDatabase,
  },
  props: {},
  data () {
    return {
      d_patientData: require('@/example_data/patient_data').example_data,
      d_diagnosisData: {
        'selected': ''
      },
      d_componentExample: {
        'schema_drugs': [
          { 'code': '<diagnosis-selection :p_diagnosisData="d_diagnosisData" :p_patientData="d_patientData"></diagnosis-selection>', 'name': 'Örnek komponent kullanımı' },
        ]
      },
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style type="text/css">


</style>

